<template>
  <div>

    <v-card v-for='c in courier_keys' :key='c' class='my-4 darken-1'>
      <v-card-title class='primary white--text'>
        <template v-if='c'>{{couriers[c].label}}</template><template v-else>No Courier</template>

        <v-spacer />

        <v-btn icon dark title="Export CSV (ParcelSend)" @click="parcelsend_csv(c)"><v-icon>mdi-file-table</v-icon></v-btn>

        <v-btn icon dark title="Despatch" @click="despatch_courier(c)"><v-icon>mdi-truck</v-icon></v-btn>
        
      </v-card-title>
      <v-card-text class='pb-0 px-0'>

        <v-list dense rounded class=''>
          <template v-for='(p,i) in packages[c]' >
            <div :key='p.xid'>
              <v-divider v-if='i>0' />
              <v-list-item @click="viewPackage(p)" class="">
                <v-list-item-content class="">
                  <v-list-item-title class='d-inline-flex align-center'>
                    {{p.to_address.company_name}}
                  </v-list-item-title>
                  <v-list-item-subtitle  class='text-caption'>{{p.to_address.address.replaceAll("\n", ', ')}}</v-list-item-subtitle >
                  <v-list-item-subtitle >
                    <v-chip label v-for='j in p.jobs' :key='j.xid' class='primary mr-1' small :title="j.description" @click.stop="viewJob(j)">{{j.ref}}</v-chip>
                </v-list-item-subtitle >
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex align-center">

                    <v-btn v-if='!p.tracking_number' text small color="warning" @click.stop="updateTrackingNumber(p, c)">Add Tracking</v-btn>
                    <v-btn v-else text small color="primary" @click.stop="updateTrackingNumber(p, c)">{{ p.tracking_number }}</v-btn>

                    <v-btn icon v-if="p.tracking_url" class="overflow-hidden d-block mr-2 pa-1" large @click.stop="clickTrackingLink(p)">
                      <v-img :src="p.courier._17track_courier.logo" contain style="mix-blend-mode: multiply;" class="rounded-xl" />
                    </v-btn>

                    
                    <v-menu>
                      <template #activator="{on,attrs}">
                        <v-btn v-on="on" v-bind="attrs" fab small text><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item link @click="printLabels(p)"><v-icon class="primary--text mr-4">mdi-printer</v-icon>Labels</v-list-item>
                        <v-list-item link @click="deletePackage(p)"><v-icon class="error--text mr-4">mdi-trash-can</v-icon>Delete</v-list-item>
                      </v-list>
                    </v-menu>

                  </div>
                  
                </v-list-item-action>
              </v-list-item>
            </div>
          </template>
        </v-list>

      </v-card-text>
    </v-card>

    <PrintLabels ref="PrintLabels" />
    <ViewPackageDialog ref='viewPackageDialog' />
  </div>

</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import DownloadFile from 'downloadjs';
import ViewPackageDialog from '@/components/Dispatch/ViewPackageDialog.vue';
import PrintLabels from '@/components/Dispatch/PrintLabels.vue';
export default {
  name: 'DespatchPackages',

  components: {
    ViewPackageDialog,
    PrintLabels,
},

  data: () => ({
    loading: false,
    packages: {},
  }),

  computed: {
    courier_keys(){
      return Object.keys(this.packages);
    },
    couriers(){
      let couriers = {};
      this.courier_keys.forEach(c => {
        couriers[c] = this.packages[c][0].courier;
      });
      return couriers;
    }
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['CONFIRM', 'PROMPT']),

    refresh(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: 'despatch/packages' })
      .then(data => { this.packages = data; })
      .finally(() => { this.loading = false; });
    },

    viewPackage(p){
      //this.$refs.createPackageDialog.edit(p);
      this.$refs.viewPackageDialog.edit(p);
    },

    async deletePackage(p){
      await this.CONFIRM({ title: "Unpack", message: `Unpack the "${p.description}" package for ${p.to_address.company_name}?` })
      this.loading = true;
      await this.API({ method: "DELETE", endpoint: `despatch/packages/${p.xid}` })
      this.refresh();
      this.$emit('unpack');
    },

    viewJob(job){
      let { xid } = job;
      window.open(`/#/job/${xid}`, '_blank');
    },

    printLabels(Package){
      this.$refs.PrintLabels.open(Package.xid);
    },

    async parcelsend_csv(courierId){
      console.log(courierId);
      let csv = await this.API({ method: 'GET', endpoint: `despatch/export/parcelsend/${courierId}` });
      console.log(csv);
      DownloadFile(new Blob([Uint8Array.from(atob(csv), c => c.charCodeAt(0))]), `ParcelSend.csv`);
    },

    async updateTrackingNumber(Package){
      let tracking_number = await this.PROMPT({ title: 'Tracking Number', message: 'Enter tracking number:', placeholder: Package.tracking_number });
      // barcode decoding
      let barcode_rxs = JSON.parse(Package.courier?._17track_courier?.barcode_regex ?? "[]");
      barcode_rxs.forEach(rx => {
        let match = tracking_number.match(new RegExp(rx));
        if(match) tracking_number = match[1];
      });
      // skip if no change
      if(tracking_number == Package.tracking_number) return;
      // update
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `despatch/packages/${Package.xid}`, data: { tracking_number } })
      .then(this.refresh)
      .finally(() => { this.loading = false; })
    },

    clickTrackingLink(Package){
      window.open(Package.tracking_url, '_blank');
    },

    async despatch_courier(courierId){
      let label = this.couriers[courierId]?.label ?? "No Courier";
      await this.CONFIRM({ title: 'Despatch packages?', message: `Despatch all ${label} packages?` });
      let data = { courier: courierId || null };
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'despatch/despatch', data })
      .then(() => {
        this.$emit('despatch');
        return this.refresh();
      })
      .finally(() => { this.loading = false; });
    }

  },

  mounted() {
    this.refresh();
  },

};
</script>
