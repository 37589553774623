<template>
  <v-card class='my-4' :loading="loading" :disabled='loading'>

    <v-card-text class='' style=''>
      
      <v-data-table
        :loading="loading"
        :items="packages"
        :headers="headers"
        :server-items-length="packages_total"
        :options.sync="options"
        :footer-props="{ 'items-per-page-options':[20,50,100], 'show-first-last-page': false, 'show-current-page': true }"
      >
      <template v-slot:item.despatched="{item, value}">
        <Moment :timestamp='value' mode='timeAgo' :title="value" />
      </template>

      <template v-slot:item.courier="{item, value}">
        <span v-if="value">
          {{ value.label }}
        </span>
        <span v-else class="font-italic secondary--text">No Courier</span>
      </template>

      <template v-slot:item.to_address="{item, value}">
          <span>{{ value.company_name }}</span><br />
          <span class="text-caption">{{ value.address }}</span>
      </template>

      <template v-slot:item.jobs="{item, value}">
          <a @click="viewPackage(item)">{{ value.length }}</a>
      </template>

      <template v-slot:item.tracking_number="{item, value}">
        <div class="d-flex align-center">
          <v-btn icon v-if="item.tracking_url" class="overflow-hidden d-block mr-2 pa-1" large @click.stop="clickTrackingLink(item)">
            <v-img :src="item.courier._17track_courier.logo" contain style="mix-blend-mode: multiply;" class="rounded-xl" />
          </v-btn>
          <a @click="copyToClipboard(value)">{{ value }}</a>
        </div>
      </template>

    </v-data-table>

    </v-card-text>
    <!--<v-divider />
    <v-card-actions class='grey lighten-4'>  
    </v-card-actions>-->
    <ViewPackageDialog ref='viewPackageDialog' />
  </v-card>

</template>

<script>
import { mapActions } from 'vuex'
import Moment from '@/components/Moment'
import ViewPackageDialog from '@/components/Dispatch/ViewPackageDialog.vue';

export default {
  name: 'DespatchHistory',

  components: {
    Moment, ViewPackageDialog
  },

  data: () => ({
    loading: true,
    packages: [],
    packages_total: 0,
    headers: [
      { text: 'Date / Time', value: 'despatched', filterable: false, sortable: false },
      { text: 'Courier', value: 'courier', filterable: false, sortable: false },
      { text: 'Sent to', value: 'to_address', filterable: false, sortable: false },
      { text: 'Jobs', value: 'jobs', filterable: false, sortable: false },
      { text: 'Tracking', value: 'tracking_number', filterable: false, sortable: false },
    ],
    options: {
      page: 1, sortBy: [], sortDesc: [], itemsPerPage: 20
    },
  }),
  computed: {
  },
  watch: {
    'options.page'(){ this.refresh(); },
    'options.itemsPerPage'(){ this.refresh(); },
  },
  methods:{
    ...mapActions('UI', ['NOTIFY']),
    ...mapActions('API', ['API']),

    refresh(){
      this.loading = true;
      let data = {
        page: this.options.page,
        items_per_page: this.options.itemsPerPage,
        sort: this.options.sortBy[0] ?? undefined, sortDesc: this.options.sortDesc[0] ?? undefined,
      };
      return this.API({ method: 'POST', endpoint: 'despatch/history', data })
      .then(paginate => {
        this.packages = paginate.data;
        this.packages_total = paginate.total;
      })
      .finally(() => { this.loading = false; });
    },

    clickTrackingLink(Package){
      window.open(Package.tracking_url, '_blank');
    },

    copyToClipboard(text){
      navigator.clipboard.writeText(text);
      this.NOTIFY({ message: `📋 Copied to Clipboard.` })
    },

    viewPackage(p){
      this.$refs.viewPackageDialog.edit(p);
    },

  },
  mounted() {
    this.refresh()
  }
};
</script>
