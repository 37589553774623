<template>
  <div class="fill-height d-flex flex-column" >

    <div class="flex-grow-1" style="position:relative;">
      <div class="" style="position:absolute;width:100%;height:100%;overflow:auto;">

        <v-card class='my-2' :loading="loading" :disabled='loading'>

          <v-card-text class='' style=''>

            <v-treeview
            xv-if='loading'
            :items='treeData' item-key='xid'
            selectable v-model='selected'
            :open.sync='expanded' open-all
            class='' dense
            >
            <template #label="{item}">

            <template v-if='item.type == "root"'>
            <b>All Jobs</b>
            </template>

            <template v-if='item.type == "location"'>
            
              <v-card class='mt-2'>
                <v-card-title class='py-1 primary white--text'>

                  <v-row>
                    <v-col cols="12" lg="6" @click="()=>clickCustomerLink(item.data.customer)" style="cursor:pointer;">
                      <div class="text-subtitle-2">{{item.data.location.company_name}}</div>
                      <div class="text-caption">{{ item.data.location.address.replaceAll("\n", ", ") }}</div>
                    </v-col>

                    <v-col cols="12" lg="5">
                      <div class="text-subtitle-2" v-if="item.data.location.courier">Preferred Courier: {{ item.data.location.courier.label }}</div>
                      <div class="text-caption">{{ item.data.location.dispatch_notes }}</div>
                    </v-col>

                    <v-col cols="12" lg="1" class="d-flex align-center justify-end">

                      <div>
                        <v-btn icon dark class="primary" @click.stop="clickSeeJobs(item.data.location)">
                          <v-icon color="white">mdi-briefcase</v-icon>
                        </v-btn>
                      </div>
                      
                    </v-col>

                  </v-row>

                </v-card-title>
              </v-card>

            </template>

            <template v-if='item.type == "job"'>
              <v-card outlined class="mt-2">
                <v-card-title class='py-1 text-body-2 grey lighten-4'>
                  <v-row class="">

                    <v-col cols="12" lg="11" class="">
                      <a @click="()=>clickJobLink(item.data)" class='primary--text text-subtitle-2 mr-2'>{{item.data.ref}}</a>
                      <span class='mr-2 text-caption' v-if="item.data.job_type">({{item.data.job_type.name}})</span>
                      <span class=''>{{item.data.description}}</span>
                    </v-col>

                    <v-col cols="12" lg="1" class="d-flex align-center justify-end">
                      <div class="" v-if="overdue(item.data.date_due)">
                        <v-icon class="warning--text" title="Due for despatch">mdi-truck-alert</v-icon>
                      </div>
                    </v-col>

                  </v-row>
                </v-card-title>
              </v-card>
            </template>

            </template>
            </v-treeview>

          </v-card-text>

        </v-card>

      </div>
    </div>

    <div class="py-2">
      <v-card class="primary">
        <v-card-actions>
          <v-spacer />
          <v-btn outlined :disabled='!selected.length' color="white" @click='queuePacking'>
          <v-icon class='mr-4'>mdi-package-variant</v-icon>Queue Packing ({{selected.length}})
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <ViewLocationActiveJobs ref="viewLocationJobs" />

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import ViewLocationActiveJobs from '@/components/Dispatch/ViewLocationActiveJobs'

export default {
  name: 'JobsReady',

  components: {
    ViewLocationActiveJobs
  },

  data: () => ({
    loading: true,
    dispatchable_jobs: [],
    selected: [],
    expanded: [],
  }),
  computed: {

    treeData(){
      let tree = [];
      this.dispatchable_jobs.forEach(j => {
        let locationId = j.ship_to?.xid;
        if(!locationId) return; //skip if no location is set
        // Add jobs location to the root tree if not in the list already
        if(tree.findIndex(l => l.xid == locationId) == -1) {
          tree.push({
            xid: locationId,
            type: 'location',
            children: [], //jobs
            data: {
              location: j.ship_to,
              customer: j.customer,
            }});
        }
        // add job to the location tree
        tree.find(l => l.xid == locationId).children.push({
          xid: j.xid,
          type: 'job',
          data: j
        });
      });
      // sort base level tree (customer)
      tree.sort((customer1, customer2) => {
        let courier1 = customer1.data.location.courier?.label ?? "";
        let courier2 = customer2.data.location.courier?.label ?? "";
        let name1 = customer1.data.location.company_name ?? "";
        let name2 = customer1.data.location.company_name ?? "";
        return courier1.localeCompare(courier2) || name1.localeCompare(name2);
      });
      return tree;
    },

  },
  watch: {
    //selected(selected){ this.validateSelected(selected); },
  },
  methods:{
    ...mapActions('UI', ['CONFIRM']),
    ...mapActions('API', ['API']),

    refreshJobList(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: 'dispatch/list' })
      .then(jobs => { this.dispatchable_jobs = jobs; })
      .finally(() => { this.loading = false; });
    },

    validateSelected(selected){
      if(!selected.length) return;
      let valid = true;
      let location = this.dispatchable_jobs.find(j => j.xid == selected[0]).ship_to;
      selected.forEach(j_xid => {
        let job = this.dispatchable_jobs.find(j => j.xid == j_xid);
        if(job.ship_to.xid !== location.xid) valid = false;
      });
      if(!valid) this.selected.pop();
    },

    queuePacking(){
      let jobs = this.dispatchable_jobs
        .filter(j => this.selected.includes(j.xid))
        .map(j => j.xid);
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'dispatch/queue_packing', data: { jobs } })
      .then(() => {
        this.refreshJobList();
        this.$emit('queue');
      })
      .catch(() => { this.loading = false; });
    },

    expandAll(){
      this.expanded = this.treeData.map(item => item.xid);
    },

    overdue(date){
      if(!date) return false;
      let today = new moment().endOf('day');
      return new moment(date).isBefore(today);
    },

    clickCustomerLink(customer){
      let { xid } = customer;
      window.open(`/#/customer/${xid}`, '_blank');
    },

    clickJobLink(job){
      let { xid } = job;
      window.open(`/#/job/${xid}`, '_blank');
    },

    clickSeeJobs(location){ this.$refs['viewLocationJobs'].open(location); },

  },
  mounted() {
    this.refreshJobList()
    .then(this.expandAll)
  }
};
</script>
