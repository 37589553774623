<template>
  <v-container id="page" v-if='LOGGED_IN' class="py-0 fill-height">
    <v-row class="justify-center fill-height"><v-col cols=12 xl=10 class="py-0 d-flex flex-column">

      <v-card class="primary" dark>
        <v-tabs centered icons-and-text fixed-tabs hidxe-slider show-arrows
          background-color='transparent'
          v-model='tab'
          class='flex-grow-0'
        >
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab key="completed">Completed Jobs<v-icon>mdi-briefcase-variant</v-icon></v-tab>
          <v-tab key="packing">Packing<v-icon>mdi-package-variant</v-icon></v-tab>
          <v-tab key="despatch">Despatch<v-icon>mdi-truck</v-icon></v-tab>
          <v-tab key="history">History<v-icon>mdi-history</v-icon></v-tab>
        </v-tabs>
      </v-card>

      <v-tabs-items v-model="tab" class="my-2 fill-height transparent">
        <v-tab-item key="completed" class="fill-height">
          <CompletedJobs @queue='reloadPacking' />
        </v-tab-item>

        <v-tab-item key="packing" class="fill-height">
          <PackageJobs ref="packing" @pack="reloadDespatch" />
        </v-tab-item>
          

        <v-tab-item key="despatch" class="fill-height">
          <DespatchPackages ref="despatch" @unpack="reloadPacking" @despatch="reloadHistory" />
        </v-tab-item>

        <v-tab-item key="history" class="fill-height">
          <DespatchHistory ref="history" />
        </v-tab-item>

      </v-tabs-items>

    </v-col></v-row>
  </v-container>
</template>

<style lang='scss' >
@import "@/assets/common";

#page *::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
  &-thumb{
    border-radius: 10px;
    background-color: rgba($secondary, 0.5);
    &:hover{
      background-color: rgba($secondary, 1);
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import CompletedJobs from './CompletedJobs'
import PackageJobs from './PackageJobs'
import DespatchPackages from './DespatchPackages'
import DespatchHistory from './DespatchHistory'
export default {
  name: 'Dispatch',

  components: {
    CompletedJobs, PackageJobs, DespatchPackages, DespatchHistory
  },

  data: () => ({
    tab: null,
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN']),
  },
  watch: {
  },
  methods:{
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('UI', ['SHOW_NAVBARS', 'SET_PAGE_TITLE']),

    reloadPacking(){ this.$refs['packing']?.refresh(); },
    reloadDespatch(){ this.$refs['despatch']?.refresh(); },
    reloadHistory(){ this.$refs['history']?.refresh(); }

  },
  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS();
      this.SET_PAGE_TITLE('Dispatch');
    });
  }
};
</script>
