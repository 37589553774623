var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    staticClass: "py-0 fill-height",
    attrs: {
      "id": "page"
    }
  }, [_c('v-row', {
    staticClass: "justify-center fill-height"
  }, [_c('v-col', {
    staticClass: "py-0 d-flex flex-column",
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('v-card', {
    staticClass: "primary",
    attrs: {
      "dark": ""
    }
  }, [_c('v-tabs', {
    staticClass: "flex-grow-0",
    attrs: {
      "centered": "",
      "icons-and-text": "",
      "fixed-tabs": "",
      "hidxe-slider": "",
      "show-arrows": "",
      "background-color": "transparent"
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tabs-slider', {
    attrs: {
      "color": "white"
    }
  }), _c('v-tab', {
    key: "completed"
  }, [_vm._v("Completed Jobs"), _c('v-icon', [_vm._v("mdi-briefcase-variant")])], 1), _c('v-tab', {
    key: "packing"
  }, [_vm._v("Packing"), _c('v-icon', [_vm._v("mdi-package-variant")])], 1), _c('v-tab', {
    key: "despatch"
  }, [_vm._v("Despatch"), _c('v-icon', [_vm._v("mdi-truck")])], 1), _c('v-tab', {
    key: "history"
  }, [_vm._v("History"), _c('v-icon', [_vm._v("mdi-history")])], 1)], 1)], 1), _c('v-tabs-items', {
    staticClass: "my-2 fill-height transparent",
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    key: "completed",
    staticClass: "fill-height"
  }, [_c('CompletedJobs', {
    on: {
      "queue": _vm.reloadPacking
    }
  })], 1), _c('v-tab-item', {
    key: "packing",
    staticClass: "fill-height"
  }, [_c('PackageJobs', {
    ref: "packing",
    on: {
      "pack": _vm.reloadDespatch
    }
  })], 1), _c('v-tab-item', {
    key: "despatch",
    staticClass: "fill-height"
  }, [_c('DespatchPackages', {
    ref: "despatch",
    on: {
      "unpack": _vm.reloadPacking,
      "despatch": _vm.reloadHistory
    }
  })], 1), _c('v-tab-item', {
    key: "history",
    staticClass: "fill-height"
  }, [_c('DespatchHistory', {
    ref: "history"
  })], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }