var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fill-height d-flex flex-column"
  }, [_c('div', {
    staticClass: "flex-grow-1",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "absolute",
      "width": "100%",
      "height": "100%",
      "overflow": "auto"
    }
  }, [_c('v-card', {
    staticClass: "my-2",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-text', {}, [_c('v-treeview', {
    attrs: {
      "xv-if": "loading",
      "items": _vm.treeData,
      "item-key": "xid",
      "selectable": "",
      "open": _vm.expanded,
      "open-all": "",
      "dense": ""
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.type == "root" ? [_c('b', [_vm._v("All Jobs")])] : _vm._e(), item.type == "location" ? [_c('v-card', {
          staticClass: "mt-2"
        }, [_c('v-card-title', {
          staticClass: "py-1 primary white--text"
        }, [_c('v-row', [_c('v-col', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "cols": "12",
            "lg": "6"
          },
          on: {
            "click": function click() {
              return _vm.clickCustomerLink(item.data.customer);
            }
          }
        }, [_c('div', {
          staticClass: "text-subtitle-2"
        }, [_vm._v(_vm._s(item.data.location.company_name))]), _c('div', {
          staticClass: "text-caption"
        }, [_vm._v(_vm._s(item.data.location.address.replaceAll("\n", ", ")))])]), _c('v-col', {
          attrs: {
            "cols": "12",
            "lg": "5"
          }
        }, [item.data.location.courier ? _c('div', {
          staticClass: "text-subtitle-2"
        }, [_vm._v("Preferred Courier: " + _vm._s(item.data.location.courier.label))]) : _vm._e(), _c('div', {
          staticClass: "text-caption"
        }, [_vm._v(_vm._s(item.data.location.dispatch_notes))])]), _c('v-col', {
          staticClass: "d-flex align-center justify-end",
          attrs: {
            "cols": "12",
            "lg": "1"
          }
        }, [_c('div', [_c('v-btn', {
          staticClass: "primary",
          attrs: {
            "icon": "",
            "dark": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.clickSeeJobs(item.data.location);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("mdi-briefcase")])], 1)], 1)])], 1)], 1)], 1)] : _vm._e(), item.type == "job" ? [_c('v-card', {
          staticClass: "mt-2",
          attrs: {
            "outlined": ""
          }
        }, [_c('v-card-title', {
          staticClass: "py-1 text-body-2 grey lighten-4"
        }, [_c('v-row', {}, [_c('v-col', {
          attrs: {
            "cols": "12",
            "lg": "11"
          }
        }, [_c('a', {
          staticClass: "primary--text text-subtitle-2 mr-2",
          on: {
            "click": function click() {
              return _vm.clickJobLink(item.data);
            }
          }
        }, [_vm._v(_vm._s(item.data.ref))]), item.data.job_type ? _c('span', {
          staticClass: "mr-2 text-caption"
        }, [_vm._v("(" + _vm._s(item.data.job_type.name) + ")")]) : _vm._e(), _c('span', {}, [_vm._v(_vm._s(item.data.description))])]), _c('v-col', {
          staticClass: "d-flex align-center justify-end",
          attrs: {
            "cols": "12",
            "lg": "1"
          }
        }, [_vm.overdue(item.data.date_due) ? _c('div', {}, [_c('v-icon', {
          staticClass: "warning--text",
          attrs: {
            "title": "Due for despatch"
          }
        }, [_vm._v("mdi-truck-alert")])], 1) : _vm._e()])], 1)], 1)], 1)] : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "py-2"
  }, [_c('v-card', {
    staticClass: "primary"
  }, [_c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "outlined": "",
      "disabled": !_vm.selected.length,
      "color": "white"
    },
    on: {
      "click": _vm.queuePacking
    }
  }, [_c('v-icon', {
    staticClass: "mr-4"
  }, [_vm._v("mdi-package-variant")]), _vm._v("Queue Packing (" + _vm._s(_vm.selected.length) + ") ")], 1)], 1)], 1)], 1), _c('ViewLocationActiveJobs', {
    ref: "viewLocationJobs"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }