var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.courier_keys, function (c) {
    return _c('v-card', {
      key: c,
      staticClass: "my-4 darken-1"
    }, [_c('v-card-title', {
      staticClass: "primary white--text"
    }, [c ? [_vm._v(_vm._s(_vm.couriers[c].label))] : [_vm._v("No Courier")], _c('v-spacer'), _c('v-btn', {
      attrs: {
        "icon": "",
        "dark": "",
        "title": "Export CSV (ParcelSend)"
      },
      on: {
        "click": function click($event) {
          return _vm.parcelsend_csv(c);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-file-table")])], 1), _c('v-btn', {
      attrs: {
        "icon": "",
        "dark": "",
        "title": "Despatch"
      },
      on: {
        "click": function click($event) {
          return _vm.despatch_courier(c);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-truck")])], 1)], 2), _c('v-card-text', {
      staticClass: "pb-0 px-0"
    }, [_c('v-list', {
      attrs: {
        "dense": "",
        "rounded": ""
      }
    }, [_vm._l(_vm.packages[c], function (p, i) {
      return [_c('div', {
        key: p.xid
      }, [i > 0 ? _c('v-divider') : _vm._e(), _c('v-list-item', {
        on: {
          "click": function click($event) {
            return _vm.viewPackage(p);
          }
        }
      }, [_c('v-list-item-content', {}, [_c('v-list-item-title', {
        staticClass: "d-inline-flex align-center"
      }, [_vm._v(" " + _vm._s(p.to_address.company_name) + " ")]), _c('v-list-item-subtitle', {
        staticClass: "text-caption"
      }, [_vm._v(_vm._s(p.to_address.address.replaceAll("\n", ', ')))]), _c('v-list-item-subtitle', _vm._l(p.jobs, function (j) {
        return _c('v-chip', {
          key: j.xid,
          staticClass: "primary mr-1",
          attrs: {
            "label": "",
            "small": "",
            "title": j.description
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.viewJob(j);
            }
          }
        }, [_vm._v(_vm._s(j.ref))]);
      }), 1)], 1), _c('v-list-item-action', [_c('div', {
        staticClass: "d-flex align-center"
      }, [!p.tracking_number ? _c('v-btn', {
        attrs: {
          "text": "",
          "small": "",
          "color": "warning"
        },
        on: {
          "click": function click($event) {
            $event.stopPropagation();
            return _vm.updateTrackingNumber(p, c);
          }
        }
      }, [_vm._v("Add Tracking")]) : _c('v-btn', {
        attrs: {
          "text": "",
          "small": "",
          "color": "primary"
        },
        on: {
          "click": function click($event) {
            $event.stopPropagation();
            return _vm.updateTrackingNumber(p, c);
          }
        }
      }, [_vm._v(_vm._s(p.tracking_number))]), p.tracking_url ? _c('v-btn', {
        staticClass: "overflow-hidden d-block mr-2 pa-1",
        attrs: {
          "icon": "",
          "large": ""
        },
        on: {
          "click": function click($event) {
            $event.stopPropagation();
            return _vm.clickTrackingLink(p);
          }
        }
      }, [_c('v-img', {
        staticClass: "rounded-xl",
        staticStyle: {
          "mix-blend-mode": "multiply"
        },
        attrs: {
          "src": p.courier._17track_courier.logo,
          "contain": ""
        }
      })], 1) : _vm._e(), _c('v-menu', {
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs;
            return [_c('v-btn', _vm._g(_vm._b({
              attrs: {
                "fab": "",
                "small": "",
                "text": ""
              }
            }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
          }
        }], null, true)
      }, [_c('v-list', {
        attrs: {
          "dense": ""
        }
      }, [_c('v-list-item', {
        attrs: {
          "link": ""
        },
        on: {
          "click": function click($event) {
            return _vm.printLabels(p);
          }
        }
      }, [_c('v-icon', {
        staticClass: "primary--text mr-4"
      }, [_vm._v("mdi-printer")]), _vm._v("Labels")], 1), _c('v-list-item', {
        attrs: {
          "link": ""
        },
        on: {
          "click": function click($event) {
            return _vm.deletePackage(p);
          }
        }
      }, [_c('v-icon', {
        staticClass: "error--text mr-4"
      }, [_vm._v("mdi-trash-can")]), _vm._v("Delete")], 1)], 1)], 1)], 1)])], 1)], 1)];
    })], 2)], 1)], 1);
  }), _c('PrintLabels', {
    ref: "PrintLabels"
  }), _c('ViewPackageDialog', {
    ref: "viewPackageDialog"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }