var render = function () {
  var _vm$location;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.isOpen,
      "max-width": "1200",
      "scrollable": "",
      "persistent": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" " + _vm._s((_vm$location = _vm.location) === null || _vm$location === void 0 ? void 0 : _vm$location.company_name) + " ")]), _c('v-card-text', {
    staticClass: "pt-4",
    staticStyle: {
      "max-height": "80vh",
      "height": "auto"
    }
  }, [_c('v-data-table', {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.headers,
      "items": _vm.jobs,
      "dense": ""
    },
    on: {
      "click:row": _vm.clickJobRow
    },
    scopedSlots: _vm._u([{
      key: "item.description",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.description ? _c('span', [_vm._v(_vm._s(item.description))]) : _c('span', [_c('i', {
          staticClass: "grey--text"
        }, [_vm._v("No Description")])])];
      }
    }, {
      key: "item.job_type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.job_type ? _c('span', [_vm._v(_vm._s(item.job_type.name))]) : _vm._e()];
      }
    }, {
      key: "item.date_due",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.date_due ? _c('Moment', {
          attrs: {
            "timestamp": item.date_due,
            "xmode": "calendar"
          }
        }) : _c('span', [_c('i', {
          staticClass: "grey--text"
        }, [_vm._v("-")])])];
      }
    }, {
      key: "item.recent_activity",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [value ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                attrs = _ref5.attrs;
              return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_vm._v(_vm._s(value.task.title))])];
            }
          }], null, true)
        }, [_vm._v(" Assigned to " + _vm._s(value.delegate) + " "), _c('Moment', {
          attrs: {
            "timestamp": value.created_at,
            "mode": "timeAgo"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click($event) {
        _vm.isOpen = false;
      }
    }
  }, [_vm._v("Done")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }