var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.isOpen,
      "max-width": "1050",
      "scrollable": "",
      "persistent": ""
    }
  }, [_vm.isOpen ? _c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Package ")]), _c('v-card-text', {
    staticClass: "pt-4",
    staticStyle: {
      "max-height": "80vh",
      "height": "auto"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12 pb-0 text-caption"
    }
  }, [_vm._v(" Destination "), _c('v-divider', {
    staticClass: "pb-0"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Contact Name"
    },
    model: {
      value: _vm.data.name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "name", $$v);
      },
      expression: "data.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Contact Phone"
    },
    model: {
      value: _vm.data.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "phone", $$v);
      },
      expression: "data.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Business"
    },
    model: {
      value: _vm.data.company_name,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "company_name", $$v);
      },
      expression: "data.company_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Address"
    },
    model: {
      value: _vm.data.address_line1,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "address_line1", $$v);
      },
      expression: "data.address_line1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Address"
    },
    model: {
      value: _vm.data.address_line2,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "address_line2", $$v);
      },
      expression: "data.address_line2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Address"
    },
    model: {
      value: _vm.data.address_line3,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "address_line3", $$v);
      },
      expression: "data.address_line3"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Suburb / Town"
    },
    model: {
      value: _vm.data.city_locality,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "city_locality", $$v);
      },
      expression: "data.city_locality"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "State"
    },
    model: {
      value: _vm.data.state_province,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "state_province", $$v);
      },
      expression: "data.state_province"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Postcode"
    },
    model: {
      value: _vm.data.postal_code,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "postal_code", $$v);
      },
      expression: "data.postal_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Country"
    },
    model: {
      value: _vm.data.country_code,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "country_code", $$v);
      },
      expression: "data.country_code"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "12 pb-0 text-caption"
    }
  }, [_vm._v(" Package "), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "float-right primary--text",
          attrs: {
            "x-small": "",
            "text": ""
          },
          on: {
            "click": _vm.loadTemplates
          }
        }, on), [_vm._v("Templates")])];
      }
    }], null, false, 3868859125)
  }, [_c('v-list', {
    attrs: {
      "dense": "",
      "loading": ""
    }
  }, [!_vm.templatesLoaded ? _c('div', {
    staticClass: "text-center"
  }, [_c('v-progress-circular', {
    attrs: {
      "size": 20,
      "color": "primary",
      "indeterminate": ""
    }
  })], 1) : _vm._e(), _vm._l(_vm.templates, function (t) {
    return _c('v-list-item', {
      key: t.xid,
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.useTemplate(t);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(t.label))]), t.courier ? _c('v-list-item-subtitle', [_vm._v(_vm._s(t.courier.label))]) : _vm._e()], 1)], 1);
  })], 2)], 1), _c('v-divider', {
    staticClass: "pb-0"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Contents"
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Length",
      "suffix": "cm"
    },
    model: {
      value: _vm.data.length,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "length", $$v);
      },
      expression: "data.length"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Width",
      "suffix": "cm"
    },
    model: {
      value: _vm.data.width,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "width", $$v);
      },
      expression: "data.width"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Height",
      "suffix": "cm"
    },
    model: {
      value: _vm.data.height,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "height", $$v);
      },
      expression: "data.height"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Weight",
      "suffix": "kg"
    },
    model: {
      value: _vm.data.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "weight", $$v);
      },
      expression: "data.weight"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": "dispatch/couriers",
      "outlined": "",
      "dense": "",
      "label": "Courier",
      "itemText": "label",
      "itemValue": "xid"
    },
    model: {
      value: _vm.data.courier,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "courier", $$v);
      },
      expression: "data.courier"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12 pb-0 text-caption"
    }
  }, [_vm._v(" Label "), _c('v-divider', {
    staticClass: "pb-0"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    ref: "tracking_number",
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Tracking Number"
    },
    on: {
      "keyup": _vm.tracking_keyup,
      "change": _vm.tracking_validate
    },
    model: {
      value: _vm.data.tracking_number,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "tracking_number", $$v);
      },
      expression: "data.tracking_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12 pb-0 text-caption"
    }
  }, [_vm._v(" Jobs "), _c('v-divider', {
    staticClass: "pb-0"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.data.jobs, function (j) {
    return _c('v-chip', {
      key: j.xid,
      staticClass: "primary mr-1 mb-1",
      attrs: {
        "label": "",
        "small": "",
        "title": j.description
      }
    }, [_vm._v(" " + _vm._s(j.ref) + " ")]);
  }), 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.createPackage
    }
  }, [_vm._v("Save")]), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.isOpen = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }