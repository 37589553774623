var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "400",
      "scrollable": "",
      "persistent": ""
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Print Labels ")]), _c('v-card-text', {
    staticClass: "px-0",
    staticStyle: {
      "height": "50vh",
      "max-height": "300px"
    }
  }, [_c('v-list', _vm._l(_vm.labels, function (label) {
    return _c('v-list-item', {
      key: label.xid
    }, [_c('v-list-item-title', {
      staticClass: "font-weight-medium"
    }, [_vm._v(_vm._s(label.title))]), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.printLabel(label);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-printer")])], 1)], 1)], 1);
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Done")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }