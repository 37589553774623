<template>
  <v-dialog :value='isOpen' max-width="1200" scrollable persistent>
    <v-card>
      <v-card-title class='primary white--text'>
        {{ location?.company_name }}
      </v-card-title>
      <v-card-text style='max-height:80vh;height:auto;' class='pt-4'>


        <v-data-table
        :loading="loading" :headers='headers' :items='jobs'
        dense
        @click:row='clickJobRow'
      >
      <template v-slot:item.description="{item}">
        <span v-if='item.description'>{{item.description}}</span>
        <span v-else><i class='grey--text'>No Description</i></span>
      </template>
      <template v-slot:item.job_type="{item}">
        <span v-if='item.job_type'>{{ item.job_type.name }}</span>
      </template>
      <template v-slot:item.date_due="{item}">
        <Moment v-if='item.date_due' :timestamp='item.date_due' xmode='calendar' />
        <span v-else><i class='grey--text'>-</i></span>
      </template>
      <template v-slot:item.recent_activity="{value}">
          <v-tooltip bottom v-if='value'>
            <template #activator="{on,attrs}">
              <div v-on='on' v-bind='attrs'>{{value.task.title}}</div>
            </template>
            Assigned to {{value.delegate}}
            <Moment :timestamp='value.created_at' mode='timeAgo' />
          </v-tooltip>
      </template>
    </v-data-table>



      </v-card-text>
      <v-divider />
      <v-card-actions class='grey lighten-4'>
        <v-spacer />
        <v-btn class='primary' @click='isOpen=false'>Done</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import Moment from '@/components/Moment'
export default {
  name: 'ViewLocationActiveJobs',

  components: {
    Moment
  },

  data: () => ({
    isOpen: false,
    location: null,
    loading: false,
    headers: [
      { text: 'Job#', value: 'ref', sortable: false },
      { text: 'Type', value: 'job_type', sortable: false },
      { text: 'Job Name', value: 'description',sortable: false },
      { text: 'Status', value: 'status.name', sortable: false, },
      { text: 'Last QMS', value: 'recent_activity', sortable: false, },
      { text: 'Due', value: 'date_due', sortable: true, align: 'center' },
    ],
    jobs: [],

  }),

  computed: {
  },

  methods:{
    ...mapActions("API", ['API']),

    open(location){
      this.location = location;
      this.isOpen = true;
      this.refresh();
    },

    async refresh(){
      this.loading = true;
      this.jobs = [];
      this.jobs = await this.API({ method: 'GET', endpoint: `locations/${this.location.xid}/active_jobs` });
      this.loading = false;
    },

    clickJobRow(job){ window.open(`/#/job/${job.xid}/`, "_blank"); },

  },

  mounted() {
  },

};
</script>
