var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "my-4",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-text', {}, [_c('v-data-table', {
    attrs: {
      "loading": _vm.loading,
      "items": _vm.packages,
      "headers": _vm.headers,
      "server-items-length": _vm.packages_total,
      "options": _vm.options,
      "footer-props": {
        'items-per-page-options': [20, 50, 100],
        'show-first-last-page': false,
        'show-current-page': true
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.despatched",
      fn: function fn(_ref) {
        var item = _ref.item,
          value = _ref.value;
        return [_c('Moment', {
          attrs: {
            "timestamp": value,
            "mode": "timeAgo",
            "title": value
          }
        })];
      }
    }, {
      key: "item.courier",
      fn: function fn(_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [value ? _c('span', [_vm._v(" " + _vm._s(value.label) + " ")]) : _c('span', {
          staticClass: "font-italic secondary--text"
        }, [_vm._v("No Courier")])];
      }
    }, {
      key: "item.to_address",
      fn: function fn(_ref3) {
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('span', [_vm._v(_vm._s(value.company_name))]), _c('br'), _c('span', {
          staticClass: "text-caption"
        }, [_vm._v(_vm._s(value.address))])];
      }
    }, {
      key: "item.jobs",
      fn: function fn(_ref4) {
        var item = _ref4.item,
          value = _ref4.value;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.viewPackage(item);
            }
          }
        }, [_vm._v(_vm._s(value.length))])];
      }
    }, {
      key: "item.tracking_number",
      fn: function fn(_ref5) {
        var item = _ref5.item,
          value = _ref5.value;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [item.tracking_url ? _c('v-btn', {
          staticClass: "overflow-hidden d-block mr-2 pa-1",
          attrs: {
            "icon": "",
            "large": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.clickTrackingLink(item);
            }
          }
        }, [_c('v-img', {
          staticClass: "rounded-xl",
          staticStyle: {
            "mix-blend-mode": "multiply"
          },
          attrs: {
            "src": item.courier._17track_courier.logo,
            "contain": ""
          }
        })], 1) : _vm._e(), _c('a', {
          on: {
            "click": function click($event) {
              return _vm.copyToClipboard(value);
            }
          }
        }, [_vm._v(_vm._s(value))])], 1)];
      }
    }])
  })], 1), _c('ViewPackageDialog', {
    ref: "viewPackageDialog"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }