<template>
  <v-dialog :value='isOpen' max-width="1050" scrollable persistent>
    <v-card :loading='loading' :disabled='loading' v-if='isOpen'>
      <v-card-title class='primary white--text'>
        Package
      </v-card-title>
      <v-card-text style='max-height:80vh;height:auto;' class='pt-4'>

        <v-row>
          <v-col cols=6>
            <v-row>
              <v-col cols="12 pb-0 text-caption">
                Destination
                <!--<v-btn x-small text class='float-right primary--text'>Address Book</v-btn>-->
                <v-divider class='pb-0' />
              </v-col>

              <v-col cols="6"><v-text-field outlined dense hide-details label="Contact Name" v-model='data.name' /></v-col>
              <v-col cols="6"><v-text-field outlined dense hide-details label="Contact Phone" v-model='data.phone' /></v-col>
              <v-col cols="12"><v-text-field outlined dense hide-details label="Business" v-model='data.company_name' /></v-col>
              <v-col cols="12"><v-text-field outlined dense hide-details label="Address" v-model='data.address_line1' /></v-col>
              <v-col cols="12"><v-text-field outlined dense hide-details label="Address" v-model='data.address_line2' /></v-col>
              <v-col cols="12"><v-text-field outlined dense hide-details label="Address" v-model='data.address_line3' /></v-col>
              <v-col cols="6"><v-text-field outlined dense hide-details label="Suburb / Town" v-model='data.city_locality' /></v-col>
              <v-col cols="3"><v-text-field outlined dense hide-details label="State" v-model='data.state_province' /></v-col>
              <v-col cols="3"><v-text-field outlined dense hide-details label="Postcode" v-model='data.postal_code' /></v-col>
              <v-col cols="4"><v-text-field outlined dense hide-details label="Country" v-model='data.country_code' /></v-col>
            </v-row>
          </v-col>
          <v-col cols=6>
            <!-- Jobs -->
            <v-row class="">

              <v-col cols="12 pb-0 text-caption">
                Package
                <v-menu class offset-y>
                  <template #activator="{on}">
                    <v-btn v-on='on' x-small text class='float-right primary--text' @click='loadTemplates'>Templates</v-btn>
                  </template>
                  <v-list dense loading>
                    <div class='text-center' v-if='!templatesLoaded'>
                      <v-progress-circular :size='20' color='primary' indeterminate />
                    </div>
                    <v-list-item v-for='t in templates' :key='t.xid' link @click='useTemplate(t)'>
                      <v-list-item-content>
                        <v-list-item-title>{{t.label}}</v-list-item-title>
                        <v-list-item-subtitle v-if='t.courier'>{{t.courier.label}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-divider class='pb-0' />
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Contents" v-model='data.description' />
              </v-col>
              <v-col cols="3">
                <v-text-field outlined dense hide-details label="Length" suffix="cm" v-model='data.length' />
              </v-col>
              <v-col cols="3">
                <v-text-field outlined dense hide-details label="Width" suffix="cm" v-model='data.width' />
              </v-col>
              <v-col cols="3">
                <v-text-field outlined dense hide-details label="Height" suffix="cm" v-model='data.height' />
              </v-col>
              <v-col cols="3">
                <v-text-field outlined dense hide-details label="Weight" suffix="kg" v-model='data.weight' />
              </v-col>
              <v-col cols="6">
                <LazyDropdown url='dispatch/couriers' outlined dense label='Courier' itemText='label' itemValue='xid' v-model='data.courier' />
              </v-col>
              <v-col cols="6">
                
              </v-col>

              <v-col cols="12 pb-0 text-caption">
                Label
                <v-divider class='pb-0' />
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Tracking Number" ref="tracking_number" v-model='data.tracking_number' @keyup='tracking_keyup' @change='tracking_validate' />
              </v-col>

              <v-col cols="12 pb-0 text-caption">
                Jobs
                <v-divider class='pb-0' />
              </v-col>

              <v-col cols=12>
                <v-chip
                  v-for='j in data.jobs' :key='j.xid'
                  label small class='primary mr-1 mb-1'
                  :title="j.description"
                >
                  {{j.ref}}
                </v-chip>
              </v-col>

            </v-row>
          </v-col>
        </v-row>


      </v-card-text>
      <v-divider />
      <v-card-actions class='grey lighten-4'>
        <v-spacer />
        <v-btn class='primary' @click='createPackage'>Save</v-btn>
        <!--<v-btn class='primary' @click='createPackagePrint'>Save &amp; Print</v-btn>-->
        <v-btn class='secondary' @click='isOpen=false'>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import LazyDropdown from '@/components/LazyDropdown'
export default {
  name: 'CreatePackageDialog',

  components: {
    LazyDropdown,
  },

  data: () => ({
    isOpen: false,
    loading: false,
    jobSearch: null,
    templates: [],
    templatesLoaded: false,
    data: {
      jobs: [],
      description: null,
      length: null, width: null, height: null, weight: null,
      courier: null,
      name: null, phone: null, company_name: null,
      address_line1: null, address_line2: null, address_line3: null,
      city_locality: null, state_province: null, postal_code: null, country_code: null,
      tracking_number: null,
    }
  }),

  computed: {
  },

  methods:{
    ...mapActions("API", ["API"]),
    ...mapActions("PRINTING", ["PRINT"]),
    open(jobs){
      jobs = jobs ?? [];
      let {
        address_line_1, address_line_2, address_line_3,
        state_province, city_locality, postal_code, country_code, company_name, phone,
        courier
      } = jobs[0]?.ship_to ?? {};
      let { name_first = '', name_last = '' } = jobs[0]?.contact?.user ?? {};
      let name = `${name_first} ${name_last}`;
      if(name == ' ') name = jobs[0]?.customer.name ?? null;
      this.data = {
        phone, jobs, name,
        company_name,
        city_locality,
        state_province,
        postal_code,
        country_code,
        address_line1: address_line_1,
        address_line2: address_line_2,
        address_line3: address_line_3,
        length: null, width: null, height: null, weight: null,
        description: null, courier,
        tracking_number: null,
      };
      this.isOpen = true;
    },
    edit(p){
      //edit address
      let {
        name, phone, company_name,
        address_line1, address_line2, address_line3,
        city_locality, postal_code, state_province, country_code
       } = p.to_address;
       // edit package
       let {
        description,
        length, width, height, weight,
        courier, tracking_number, jobs,
       } = p;
       //edit fields
       this.data = {
        name, phone, company_name,
        address_line1, address_line2, address_line3,
        city_locality, postal_code, state_province, country_code,
        description,
        length, width, height, weight,
        courier, tracking_number, jobs
       };
       this.isOpen = true;
    },
    async loadTemplates(){
      if(this.templatesLoaded) return;
      this.templates = await this.API({ method: 'GET', endpoint: 'dispatch/package_templates' })
      this.templatesLoaded = true;
    },
    useTemplate(t){
      let { description, length, width, height, weight, courier } = t;
      this.data = {
        ...this.data, description, length, width, height, weight, courier
      };
      this.$refs.tracking_number.focus();
    },

    createPackage(){
      let {
        description,
        name, company_name, phone,
        address_line1, address_line2, address_line3,
        state_province, city_locality, postal_code, country_code,
        length, width, height, weight,
        courier, tracking_number,
        jobs
      } = this.data;
      return this.API({ method: 'POST', endpoint: 'dispatch/package', data: {
        description,
        name, company_name, phone,
        address_line1, address_line2, address_line3,
        state_province, city_locality, postal_code, country_code,
        length, width, height, weight,
        courier: courier?.xid, tracking_number,
        jobs: jobs.map(j => j.xid),
      }})
      .then(Package => {
        this.isOpen = false;
        this.$emit('packageCreated', Package);
        return Package;
      });
    },

    tracking_keyup({key}){
      /* Barcode scanners in HID mode emit F8 key when <GS> (0x1D) is scanned.
        This inserts the <GS> control character when F8 is pressed. */
      if(key == 'F8') this.data.tracking_number += "\u001D";
    },

    tracking_validate(){
      let regex = JSON.parse(this.data.courier?._17track_courier?.barcode_regex ?? null) ?? [];
      regex.forEach(r => {
        let rx = new RegExp(r);
        let match = rx.exec(this.data.tracking_number);
        if(match){
          this.data.tracking_number = match[1];
          return;
        }
      });
    },

  },

  mounted() {
  },

};
</script>
