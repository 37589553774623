<template>
  <div>
    <v-dialog v-model='isOpen' max-width="400" scrollable persistent>
      <v-card :loading='loading' :disabled='loading'>
        <v-card-title class='primary white--text'>
          Print Labels
        </v-card-title>
        <v-card-text class='px-0' style="height:50vh;max-height:300px;">
          <v-list>
            <v-list-item v-for="label in labels" :key="label.xid">
              <v-list-item-title class="font-weight-medium">{{ label.title }}</v-list-item-title>
              <v-list-item-action><v-btn icon color="primary" @click="printLabel(label)"><v-icon>mdi-printer</v-icon></v-btn></v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class='grey lighten-4'>
          <v-spacer />
          <v-btn class='primary' @click='close'>Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
.dragMe{
  cursor: move;
}
</style>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PrintLabels',

  components: { },

  data: () => ({
    isOpen: false,
    loading: true,
    labels: [],
    packageId: null,
  }),

  computed: {
  },

  watch: {
    isOpen(isOpen){
      if(isOpen) { this.refresh(); }
    },
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('PRINTING', ['PRINT']),

    open(packageId){
      this.packageId = packageId;
      this.isOpen = true;
    },

    close(){ this.isOpen = false; },

    refresh(){
      this.labels = [];
      this.loading = true;
      return this.API({ method: 'GET', endpoint: `despatch/packages/${this.packageId}/labels` })
      .then(labels => { this.labels = labels; })
      .finally(() => { this.loading = false; });
    },

    printLabel(label){
      this.API({ method: 'GET', endpoint: `despatch/packages/${this.packageId}/labels/${label.xid}` })
      .then(pdf => {
        this.PRINT({ data: pdf, type: 'Job Label' });
      })
      .finally(() => { this.loading = false; });
    }

  },

  mounted() {

  },

};
</script>
